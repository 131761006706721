import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import global_en from "./translations/en/global.json"
import global_he from "./translations/he/global.json"
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import "./i18n.js";

{/*
i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    he: {
      global: global_he,
    },
  }, 
});


    <I18nextProvider i18n={{i18next}}>
    <App />
    </I18nextProvider>

    
*/}

ReactDOM.render(
  <React.StrictMode>
        <App />
  </React.StrictMode>,
  document.getElementById('root')
);
