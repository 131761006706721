import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import axios from 'axios';
// import emailjs from 'emailjs-com';
import Notiflix from 'notiflix';
import { Link } from 'react-router-dom'

import dataLogo from '../images/tech_data.svg';
import dataImg from '../images/tech-data.jpg';

import { useTranslation } from 'react-i18next';

import mongoDbImg from '../images/mongoImg.svg';
import CassandraImg from '../images/CassandraImg.svg';
import ElasticsearchImg from '../images/ElasticsearchImg.svg';
import KubernetesImg from '../images/KubernetesImg.svg';
import KafkaImg from '../images/KafkaImg.svg';
import BigQueryImg from '../images/BigQueryImg.svg';
import SnowflakeImg from '../images/SnowflakeImg.svg';
import tableauImg from '../images/tableauImg.svg';
import PowerBIImg from '../images/PowerBIImg.svg';
import QlikImg from '../images/QlikImg.svg';
import PyTorchImg from '../images/PyTorchImg.svg';
import tensorImg from '../images/tensorImg.svg';
import KerasImg from '../images/KerasImg.svg';
import pythonImg from '../images/pythonImg.svg';

import dataImage from '../images/dataImage.jpg'
import aiImage from '../images/dataImg.jpg'

import dataTechImage from '../images/dataTechImage.jpg'

import aiVideo1 from '../videos/data1.mp4'
import aiVideo2 from '../videos/data2.mp4'
import aiVideo3 from '../videos/data3.mp4'

const DataInfo = () => {
    useDocTitle('CodeSuits | Development, Design & Consultant - Data, ML & AI')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    const { t } = useTranslation()

    const {

        tech_data_title,
        tech_data_description1,
        tech_data_description2,
        tech_data_description3,
        tech_data_description4,
        tech_data_card1,
        tech_data_card1_description,
        tech_data_card1_description2,
        tech_data_card2,
        tech_data_card2_description,
        tech_data_card3,
        tech_data_card3_description,
        tech_data_bigdata1,
        tech_data_bigdata2,
        tech_data_mlai1,
        tech_data_mlai2,
        tech_data_letstalk,

    } = t("tech_pages.tech_data");


    const { ux_techstack,
        uxui_stack,
        ux_techstack1,
        ux_techstack2,
        ux_techstack3,
        ux_techstack4,
        ux_techstack5, } = t("portfolio_all.pages.ux");

    const { ui_techstack,
        ui_techstack1,
        ui_techstack2,
        ui_techstack3,
        ui_techstack4,
        ui_techstack5, } = t("portfolio_all.pages.ui");



    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);

    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message)

        axios({
            method: "post",
            url: process.env.REACT_APP_CONTACT_API,
            data: fData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                document.getElementById('submitBtn').disabled = true;
                document.getElementById('submitBtn').innerHTML = 'send message';
                clearInput()
                //handle success
                Notiflix.Report.success(
                    'Success',
                    response.data.message,
                    'Okay',
                );
            })
            .catch(function (error) {
                document.getElementById('submitBtn').disabled = true;
                document.getElementById('submitBtn').innerHTML = 'send message';
                //handle error
                const { response } = error;
                if (response.status === 500) {
                    Notiflix.Report.failure(
                        'An error occurred',
                        response.data.message,
                        'Okay',
                    );
                }
                if (response.data.errors !== null) {
                    setErrors(response.data.errors)
                }

            });
    }
    return (
        <>
            <div>
                <NavBar />
            </div>


            <section class="py-10 bg-gray-100 sm:py-16 lg:py-24  ">


                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 my-24"  >
                    <div class="max-w-2xl mx-auto text-center">
                        <h2 class="text-3xl font-bold leading-tight text-gray-800 sm:text-4xl lg:text-5xl">{tech_data_title}</h2>
                    </div>


                    <div class="max-w-2xl mx-auto text-center">

                        <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600"><b>{tech_data_description1}</b> </p>
                        <p>{tech_data_description2}</p>
                        <p>{tech_data_description3}</p>
                    </div>

                    <div class="max-w-2xl mx-auto text-center">

                        <p class="max-w-xl mx-auto mt-4 text-xl leading-relaxed text-gray-600"><b>{tech_data_description4}</b></p>

                    </div>
                </div>



                <div className="container mx-auto  px-4 lg:px-20" data-aos="zoom-in">

                    <div className="w-full bg-white p-8  md:px-12 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">

                        <div class="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full  sm:mt-12 lg:mt-20 lg:grid-cols-3  w-full gap-x-12 gap-y-6">


                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl ">
                                <div class="px-8 py-12">

                                    <video autoPlay loop muted className="flex justify-center mx-auto">
                                        <source src={aiVideo1} type="video/mp4" />
                                    </video>
                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_data_card1}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_data_card1_description}   </p> <p class="mt-1 text-base text-gray-600">{tech_data_card1_description2}</p>
                                </div>
                            </div>


                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <video autoPlay loop muted className="flex justify-center mx-auto">
                                        <source src={aiVideo2} type="video/mp4" />
                                    </video>
                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_data_card2}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_data_card2_description}</p>
                                </div>
                            </div>

                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <video autoPlay loop muted className="flex justify-center mx-auto">
                                        <source src={aiVideo3} type="video/mp4" />
                                    </video>
                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_data_card3}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_data_card3_description}</p>
                                </div>
                            </div>




                        </div>




                        <section class="pt-10 pb-8 overflow-hidden sm:pt-16 lg:pt-24">

                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center">
                                    <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                        {tech_data_bigdata1}</h4>

                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600">{tech_data_bigdata2}</p>
                                </div>

                                <img class="flex justify-center mx-auto py-12  mt-12 scale-150 max-w-7xl lg:min-w-0 lg:mt-0 lg:scale-100" src={dataTechImage} alt="" />


                            </div>

                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center my-12">
                                    <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                        {tech_data_mlai1}</h4>

                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600">{tech_data_mlai2}</p>
                                </div>

                                <img class="flex justify-center mx-auto py-12  mt-12 scale-150 max-w-7xl lg:min-w-0 lg:mt-0 lg:scale-100" src={aiImage} alt="" />

                            </div>

                            {/*
                            <img class="w-full min-w-full mx-auto mt-12 scale-150 max-w-7xl lg:min-w-0 lg:mt-0 lg:scale-100" src="https://cdn.rareblocks.xyz/collection/celebration/images/integration/1/services-icons.png" alt="" />
                              */}





                            {/* ---  UX Tech Stack --- */}

                            <div className="m-auto max-w-6xl py-8 p-2 md:p-12 h-5/6 w-full" id='data_stack' >

                                <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full  px-8 " data-aos="zoom-in" data-aos-delay="500">

                                    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                    </div>

                                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left overflow-auto md:overflow-scroll w-full" data-aos="fade-up">


                                        <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                            alignItems: "center"
                                        }}>



                                            <img alt="card img" className="rounded-t float-right" src={mongoDbImg} style={{ height: 42, width: 42, alignItems: 'center' }} />


                                            <div>
                                                <div >
                                                    <h3 className="my-3 text-xl text-gray-600 font-semibold text-center lg:text-center"  >
                                                        Mng.</h3>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                            alignItems: "center"
                                        }}>


                                            <img alt="card img" className="rounded-t float-right" src={CassandraImg} style={{ height: 42, width: 42 }} />

                                            <div>
                                                <div >
                                                    <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                        Cassdr.</h3>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                            alignItems: "center"
                                        }}>


                                            <img alt="card img" className="rounded-t float-right" src={ElasticsearchImg} style={{ height: 42, width: 42 }} />

                                            <div>
                                                <div >
                                                    <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                        ElstSrch.</h3>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                            alignItems: "center"
                                        }}>
                                            <img alt="card img" className="rounded-t float-right" src={KubernetesImg} style={{ height: 42, width: 42 }} />

                                            <div>
                                                <div >
                                                    <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                        Kubs.</h3>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                            alignItems: "center"
                                        }}>
                                            <img alt="card img" className="rounded-t float-right" src={KafkaImg} style={{ height: 42, width: 42 }} />

                                            <div>
                                                <div >
                                                    <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                        Kafka</h3>
                                                </div>

                                            </div>
                                        </div>




                                        <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                            alignItems: "center"
                                        }}>
                                            <img alt="card img" className="rounded-t float-right" src={BigQueryImg} style={{ height: 42, width: 42 }} />

                                            <div>
                                                <div >
                                                    <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                        BigQry.</h3>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                            alignItems: "center"
                                        }}>
                                            <img alt="card img" className="rounded-t float-right" src={SnowflakeImg} style={{ height: 42, width: 42 }} />

                                            <div>
                                                <div >
                                                    <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                        Snwflk.</h3>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                            alignItems: "center"
                                        }}>
                                            <img alt="card img" className="rounded-t float-right" src={tableauImg} style={{ height: 42, width: 42 }} />

                                            <div>
                                                <div >
                                                    <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                        Tblau.</h3>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                            alignItems: "center"
                                        }}>
                                            <img alt="card img" className="rounded-t float-right" src={PowerBIImg} style={{ height: 42, width: 42 }} />

                                            <div>
                                                <div >
                                                    <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                        PowerBI</h3>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                            alignItems: "center"
                                        }}>
                                            <img alt="card img" className="rounded-t float-right" src={QlikImg} style={{ height: 42, width: 42 }} />

                                            <div>
                                                <div >
                                                    <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                        Qlik</h3>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                            alignItems: "center"
                                        }}>
                                            <img alt="card img" className="rounded-t float-right" src={PyTorchImg} style={{ height: 42, width: 42 }} />

                                            <div>
                                                <div >
                                                    <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                        PyTorch</h3>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                            alignItems: "center"
                                        }}>
                                            <img alt="card img" className="rounded-t float-right" src={tensorImg} style={{ height: 42, width: 42 }} />

                                            <div>
                                                <div >
                                                    <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                        TnsrFlw.</h3>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                            alignItems: "center"
                                        }}>
                                            <img alt="card img" className="rounded-t float-right" src={KerasImg} style={{ height: 42, width: 42 }} />

                                            <div>
                                                <div >
                                                    <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                        Keras</h3>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                            alignItems: "center"
                                        }}>
                                            <img alt="card img" className="rounded-t float-right" src={pythonImg} style={{ height: 42, width: 42 }} />

                                            <div>
                                                <div >
                                                    <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                        Python</h3>
                                                </div>

                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>

                            <div class="max-w-2xl mx-auto text-center lg:pt-12">

                                <Link to="/get-demo" class="inline-flex items-center justify-center px-8 py-4 text-base font-semibold text-blue-900 transition-all duration-200 border-2 border-blue-900 rounded-md mt-9 hover:bg-blue-900 hover:text-white hover:border-blue-900 focus:bg-gray-900 focus:text-white focus:border-gray-900">
                                    {tech_data_letstalk}
                                </Link>

                            </div>
                        </section>



                    </div>
                </div >



            </section>







            <Footer />
        </>


    )
}

export default DataInfo;