import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import NavBar from './Navbar/NavBar';
import Footer from './Footer';
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

import './CSHealthIntro.css'

import phpLogo from '../images/casestudy/phpLogo.svg';
import nodejsLogo from '../images/casestudy/nodejsLogo.svg';
import awsLogo from '../images/casestudy/awsLogo.svg';
import kotlinLogo from '../images/casestudy/kotlinLogo.svg';
import swiftLogo from '../images/casestudy/swiftLogo.svg';
import pythonLogo from '../images/casestudy/pythonLogo.svg';

import wpsLogo from '../images/casestudy/wpsLogo.svg';
import wooLogo from '../images/casestudy/wooLogo.svg';
import joomLogo from '../images/casestudy/joomLogo.svg';



import bariImg1 from '../images/casestudy/bariImg1.jpg';
import bariImg2 from '../images/casestudy/bariImg2.jpg';
import bariImg3 from '../images/casestudy/bariImg3.jpg';


const CSBariIntro = () => {


    const { t } = useTranslation()

    const { bf_title, bf_subtitle1, bf_subtitle2 } = t("portfolio_all.pages.barifood");
    const { bf_description, bf_client_title, bf_product_title, bf_work_title, bf_client_description, bf_product_description, bf_work_description } = t("portfolio_all.pages.barifood");

    const { bf_profile,
        bf_profile_country_title,
        bf_profile_country_value,
        bf_profile_industry_titile,
        bf_profile_industry_value,
        bf_profile_team_titile,
        bf_profile_team_value } = t("portfolio_all.pages.barifood");

    const { bf_goals,
        bf_goal1,
        bf_goal2,
        bf_goal3,
        bf_goal4 } = t("portfolio_all.pages.barifood");

    const { bf_challanges,
        bf_challange_title1,
        bf_challange_1,
        bf_challange_title2,
        bf_challange_2,
        bf_challange_title3,
        bf_challange_3 } = t("portfolio_all.pages.barifood");


    const { bf_solution_title,
        bf_solution_description1,
        bf_solution_description2,
        bf_solution_description3 } = t("portfolio_all.pages.barifood");

    const { bf_techstack, bf_techstack1, bf_techstack2, bf_techstack3, bf_techstack4 } = t("portfolio_all.pages.barifood");

    const { bf_results_title,
        bf_result_title1,
        bf_result_description1,
        bf_result_title2,
        bf_result_description2,
        bf_result_title3,
        bf_result_description3 } = t("portfolio_all.pages.barifood");



    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);


    return (

        <>
            <div className="squid" id='squid'>
                <div>
                    <NavBar />
                </div>

                {/* ---  INTRO  ---*/}


                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>

                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">



                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                                {bf_title}</h1>


                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {bf_subtitle1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{bf_subtitle2}</p>
                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={bariImg1} />
                        </div>

                    </div>
                </div>



                {/* ---  DESCRIPTION  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#F3F4F5' }}>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {bf_description}</h1>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {bf_client_title}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{bf_client_description}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {bf_product_title}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{bf_product_description}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {bf_work_title}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{bf_work_description}</p>
                            </div>
                        </div>

                    </div>



                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h1 className="mb-2 md:text-2xl text-3xl font-bold text-blue-900">
                            {bf_profile}</h1>
                        <p className='my-3 text-xl text-gray-600 font-regular'><b>{bf_profile_country_title}: </b> {bf_profile_country_value}</p> <p className='my-3 text-xl text-gray-600 font-regular'><b>{bf_profile_industry_titile}: </b> {bf_profile_industry_value}</p> <p className='my-3 text-xl text-gray-600 font-font-regular'><b>{bf_profile_team_titile}: </b>{bf_profile_team_value}</p>

                    </div>
                </div>



                {/* ---  GOALS  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={bariImg2} />
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {bf_goals}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{bf_goal1}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{bf_goal2}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{bf_goal3}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{bf_goal4}</li>
                            </div>
                        </div>
                    </div>
                </div>




                {/* ---  CHALLANGES  ---*/}

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#F3F4F5' }}>
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {bf_challanges}</h1>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {bf_challange_title1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{bf_challange_1}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {bf_challange_title2}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{bf_challange_2}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {bf_challange_title3}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{bf_challange_3}</p>
                            </div>
                        </div>

                    </div>

                </div>


                {/* ---  SOLUTION  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {bf_solution_title}</h1>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{bf_solution_description1}</p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <p className='my-3 text-xl text-gray-600 font-semibold'>{bf_solution_description2}</p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <p className='my-3 text-xl text-gray-600 font-semibold'>{bf_solution_description3}</p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={bariImg3} />
                        </div>

                    </div>


                    {/* ---  Tech Stack --- */}

                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h1 className="mb-2 md:text-2xl text-3xl font-bold text-blue-900">
                            {bf_techstack}</h1>
                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                            <div className="flex-col my-6 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                                <img alt="card img" className="rounded-t float-right" src={wpsLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {bf_techstack1}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={joomLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {bf_techstack2}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={pythonLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {bf_techstack3}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={awsLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {bf_techstack4}</h3>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>



                {/* ---  RESULT  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {bf_results_title}</h1>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {bf_result_title1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{bf_result_description1}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {bf_result_title2}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{bf_result_description2}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {bf_result_title3}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{bf_result_description3}</p>
                            </div>
                        </div>

                    </div>

                </div>


            </div >
        </>
    )

}

export default CSBariIntro;




// teleporting  https://play.teleporthq.io/projects/portfolio-page-bmoc3s/editor/9fa467fc-86c5-4001-96ba-81fb58f06cb4

