import React from 'react';
import img1 from '../images/service-web.png';
import img2 from '../images/service-mobile.png';
import img3 from '../images/service-uxui.png';
import img4 from '../images/service-consult.png';

import WebDevInfo from '../pages/WebDevelopment';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


const Services = () => {

    const { t } = useTranslation();
    const { title, description } = t("services");
    const { web_title, web_description } = t("services.webdev");
    const { mobile_title, mobile_description } = t("services.mobile");
    const { design_title, design_description } = t("services.design");
    const { consult_title, consult_description } = t("services.consult");

    const { build_title1, build_title2, build_description } = t("services.build");
    const { collaborate_title1, collaborate_title2, collaborate_description } = t("services.collaborate");

    return (
        <div id="services" className="bg-gray-100 py-12" >

            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">

                        {title}
                    </h2>

                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
                        {description}
                    </h2>
                </div>



                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                        <Link to="/service-webdev" className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img1} />
                                <h2 className="font-semibold my-4 text-2xl text-center">
                                    {web_title}
                                </h2>
                                <p className="text-md font-medium">
                                    {web_description}
                                </p>
                            </div>
                        </Link>

                        <Link to="/service-mobiledev" className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                <h2 className="font-semibold my-4 text-2xl text-center">
                                    {mobile_title}
                                </h2>
                                <p className="text-md font-medium">
                                    {mobile_description}
                                </p>
                            </div>
                        </Link>

                        <Link to="/service-design" className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                <h2 className="font-semibold my-4 text-2xl text-center ">
                                    {design_title}
                                </h2>
                                <p className="text-md font-medium">
                                    {design_description}
                                </p>
                            </div>
                        </Link>

                        <Link to="/service-consultant" className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                <h2 className="font-semibold my-4 text-2xl text-center ">
                                    {consult_title}
                                </h2>
                                <p className="text-md font-medium">
                                    {consult_description}
                                </p>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>


            {/*
            <div className="my-4 py-4">
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">{title}</h2>

                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900'></div>
                </div>
                <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">{description}</h2>
            </div>
            */}

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="my-4 py-4">
                            <h3 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold"> {build_title1}<span className='font-black'> {build_title2} </span></h3>
                            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-regular text-blue-900">{build_description}</h2>
                        </div>
                        <div className="my-4 py-4">
                            <h3 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold"> {collaborate_title1}<span className='font-black'> {collaborate_title2} </span></h3>
                            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-regular text-blue-900">{collaborate_description}</h2>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}

export default Services;