import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';
import UCSquid from './pages/UCSquid';
import UCFastGrab from './pages/UCFastGrab';
import UCQuickSpace from './pages/UCQuickSpace';
import UCSaasly from './pages/UCSaasly';


import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import WebDevInfo from './pages/WebDevelopment';
import MobileDevelopment from './pages/MobileDevelopment';
import DesignInfo from './pages/DesignInfo';
import ITConsultantInfo from './pages/ITConsultantInfo';
import DataInfo from './pages/DataInfo';
import CrossPlatformInfo from './pages/CrossPlatformInfo';
import SecurityInfo from './pages/SecurityInfo';
import CloudInfo from './pages/CloudInfo';
import CaseStudySquid from "./pages/CaseStudySquid";
import CaseStudyHealthness from "./pages/CaseStudyHealthness";
import CaseStudyWeWear from "./pages/CaseStudyWeWear";
import CaseStudyFinTech from "./pages/CaseStudyFinTech";

import CaseStudyJustPrint from "./pages/CaseStudyJustPrint";
import CaseStudyBari from "./pages/CaseStudyBari";
import CaseStudyPets from "./pages/CaseStudyPets";
import CaseStudyQuickSpace from "./pages/CaseStudyQuickSpace";
import CaseStudySaasly from "./pages/CaseStudySaasly";
import CaseStudyLogo from "./pages/CaseStudyLogo";
import CaseStudyFastGrab from "./pages/CaseStudyFastGrab";
import CaseStudyRides from "./pages/CaseStudyRides";
import CaseStudyUX from "./pages/CaseStudyUX";
import CaseStudyUI from "./pages/CaseStudyUI";
import CaseStudyMarketing from "./pages/CaseStudyMarketing";
import CaseStudyDocs from "./pages/CaseStudyDocs";


import PrivacyPolicy from "./pages/PrivacyPolicy";
import LanguageSelector from "./translations/LanguageSelector";
import PortfolioPage from './pages/PortfolioPage';


import { Helmet } from 'react-helmet';
import Industries from './components/Industries';
import { useTranslation } from 'react-i18next';


// https://www.youtube.com/watch?v=BHEPVdfBAqE&list=PLC3y8-rFHvwh-K9mDlrrcDywl7CeVL2rO
//import BackgroundAnimation, { varColor2x, varColor3x, varKenburnsTop, varPanTop } from './components/BackgroundAnimation';
//import { CssBaseline } from '@mui/material';


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics , logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC241xThpea6XW9qKBOgGYK1iN3z_MAUXM",
  authDomain: "codesuitswebsite.firebaseapp.com",
  projectId: "codesuitswebsite",
  storageBucket: "codesuitswebsite.appspot.com",
  messagingSenderId: "704553536621",
  appId: "1:704553536621:web:ca6bf08f7f021df5930364",
  measurementId: "G-PP5X159Z8P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {

  const {t} = useTranslation()

  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
      logEvent(analytics,'test_firebase_analytics_${1}');
    });
  }, []);

  useDocTitle("CodeSuits | Development, Design and Consoltunt - Web and Mobile Applications");

  return (


    <>
      <Helmet>
        <title>
          {t("description")}
           </title>
        <meta
          name='description'
          content='Software engineering solutions'
        />
        <meta
          name='keywords'
          content="Software, Software Engineering, Web application, Mobile application, Android, iOS, Mobile app, CodeSuits, Coding, Software application, SW solutions, Software company"
        />
      </Helmet>

      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/get-demo" element={<DemoProduct />} />
            <Route path="/usecase-squid" element={<UCSquid />} />
            <Route path="/usecase-quickspace" element={<UCQuickSpace />} />
            <Route path="/usecase-fastgrab" element={<UCFastGrab />} />
            <Route path="/usecase-saasly" element={<UCSaasly />} />
            <Route path="/service-webdev" element={<WebDevInfo />} />
            <Route path="/service-mobiledev" element={<MobileDevelopment />} />
            <Route path="/service-design" element={<DesignInfo />} />
            <Route path="/service-consultant" element={<ITConsultantInfo />} />

            <Route path="/tech-data" element={<DataInfo />} />
            <Route path="/tech-cloud" element={<CloudInfo />} />
            <Route path="/tech-cross" element={<CrossPlatformInfo />} />
            <Route path="/tech-security" element={<SecurityInfo />} />

            <Route path="/casestudy-squid" element={<CaseStudySquid />} />
            <Route path="/casestudy-healthness" element={<CaseStudyHealthness />} />
            <Route path="/casestudy-wewear" element={<CaseStudyWeWear />} />
            <Route path="/casestudy-fintech" element={<CaseStudyFinTech />} />


            <Route path="/casestudy-print" element={<CaseStudyJustPrint />} />
            <Route path="/casestudy-bari" element={<CaseStudyBari/>} />
            <Route path="/casestudy-pets" element={<CaseStudyPets/>} />
            <Route path="/casestudy-quickspace" element={<CaseStudyQuickSpace/>} />
            <Route path="/casestudy-saasly" element={<CaseStudySaasly/>} />
            <Route path="/casestudy-logo" element={<CaseStudyLogo/>} />
            <Route path="/casestudy-grab" element={<CaseStudyFastGrab/>} />
            <Route path="/casestudy-rides" element={<CaseStudyRides/>} />
            <Route path="/casestudy-ux" element={<CaseStudyUX/>} />
            <Route path="/casestudy-ui" element={<CaseStudyUI/>} />
            <Route path="/casestudy-marketing" element={<CaseStudyMarketing/>} />
            <Route path="/casestudy-docs" element={<CaseStudyDocs/>} />
            
            

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            
            <Route path="/industries" element={<Industries />} />
            <Route path="/language" element={<LanguageSelector/>} />

            <Route path="/portfolio-all" element={<PortfolioPage/>} />
            <Route path="/casesutdy_mobile" element={<PortfolioPage/>} />
            

          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
