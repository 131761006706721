import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import {useDocTitle} from '../components/CustomHook';
import axios from 'axios';
// import emailjs from 'emailjs-com';
import Notiflix from 'notiflix';
import { Link } from 'react-router-dom'

import crossImg from '../images/tech_cross.jpg';
import crossLogo from '../images/tech_cross.svg';



const CrossPlatformInfo = () => {
    useDocTitle('CodeSuits | Development, Design & Consultant - Cross Platform Solutions')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);

    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message)

        axios({
            method: "post",
            url: process.env.REACT_APP_CONTACT_API,
            data: fData,
            headers: {
                'Content-Type':  'multipart/form-data'
            }
        })
        .then(function (response) {
            document.getElementById('submitBtn').disabled = true;
            document.getElementById('submitBtn').innerHTML = 'send message';
            clearInput()
            //handle success
            Notiflix.Report.success(
                'Success',
                response.data.message,
                'Okay',
            );
        })
        .catch(function (error) {
            document.getElementById('submitBtn').disabled = true;
            document.getElementById('submitBtn').innerHTML = 'send message';
            //handle error
            const { response } = error;
            if(response.status === 500) {
                Notiflix.Report.failure(
                    'An error occurred',
                    response.data.message,
                    'Okay',
                );
            }
            if(response.data.errors !== null) {
                setErrors(response.data.errors)
            }
        });
    }
    return (
        <>
            <div>
                <NavBar />
            </div>
            <div id='contact' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">

                    <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                        <div className="grid sm:grid-cols-2 py-1 md:py-2 lg:ml-1">
                            <div className="flex flex-col text-white">
                                <img alt="card img" className="rounded-t float-right duration-1000 w-full" style={{ height: 250, width: 300 }} src={crossLogo} />
                            </div>
                            <div className="flex">
                                <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">Cross Platform Solutions</h1>
                            </div>
                        </div>

                        <div class="scrollable-div">
                            <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">

                                <p>Releasing your application on multiple platforms while being short in time and budget, using a cross-platform solution is probably the best approach for your business.</p>


                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>


                                <p>For these reasons, plus, the capabilities of such technologies is improving all the time, the demand for a cross-platform solution is keep growing. </p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>


                                <p>In general, cross-platform technologies allow developers coding once, and deploy the resulted code on multiple platforms.</p>


                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>


                                <p>Using this approach is a cost-eefective solution for it saves precious resources like developers, time and money.</p>


                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>


                                <p>We also specialied with integrating cross-platform solutions (e.g. React Native) on existing native apps (e.g. Android/iOS).</p>


                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>


                                <p><b>Our Cross-platform tech stack:</b> </p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <li class="Bullet1"><span>React Native</span></li>
                                <li class="Bullet1"><span>Flutter</span></li>
                                <li class="Bullet1"><span>Xamarine</span></li>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>
                        </div>

                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>


                        <div className="my-2 w-1/2 lg:w-2/4">
                            <Link to="/contact" className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline">
                                Let's develop cross-platform application!
                            </Link>
                        </div>
                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                    </div>

                    <div className="w-full  lg:-mt-96 lg:w-2/6 px-12 py-12 ml-auto bg-blue-900 rounded-2xl">
                        <div className="flex flex-col text-white">
                            <img alt="card img" className="rounded-t float-right duration-1000 w-full" style={{ height: 200, width: 356.38 }} src={crossImg} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default CrossPlatformInfo;